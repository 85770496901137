<template>
  <div class="pb-3">
    <!-- <IAmOverlay
      :loading="loading"
      :spinner-variant="'info'"
    > -->
    <NavBarSearch :payload="payloadSearch" />

    <b-row class="mx-0">
      <b-col
        sm="12"
        lg="8"
        xl="9"
        class="px-50"
      >
        <div id="result-component">
          <ResultHeaderTab />

          <b-tabs
            v-model="tabIndex"
            nav-wrapper-class="d-none"
          >
            <b-tab
              v-for="(_, itineraryIndex) in getSearchPayloadArray"
              :key="itineraryIndex"
            >
              <template v-if="loading">
                <div class="d-flex-center text-primary mt-2">
                  <b-spinner
                    variant="primary"
                    class="mr-50"
                  />
                  <span>Đang tải ...</span>
                </div>
              </template>

              <template v-else-if="isEmpty(getResultTrainArray[itineraryIndex]) && !loading">
                <b-alert
                  show
                  variant="warning"
                >
                  <h4 class="text-center alert-heading">
                    Không tìm thấy chuyến tàu trên hành trình này !
                  </h4>
                </b-alert>
              </template>

              <template v-else-if="!isEmpty(getResultTrainArray[itineraryIndex])">
                <div>
                  <TrainFront
                    :itineraryIndex="itineraryIndex"
                    :trains="getResultTrainArray[itineraryIndex]"
                  />
                </div>
                <div>
                  <TrainCoaches :itineraryIndex="itineraryIndex" />
                </div>
                <div>
                  <TrainCoachLayout :itineraryIndex="itineraryIndex" />
                </div>
                <div>
                  <!-- <AddonsAndPromotions /> -->
                </div>
              </template>
            </b-tab>
          </b-tabs>
        </div>
      </b-col>

      <b-col
        v-if="['lg', 'xl'].includes(appBreakPoint)"
        lg="4"
        xl="3"
        class="px-50"
      >
        <CartTicket />
      </b-col>
    </b-row>
    <!-- </IAmOverlay> -->
  </div>
</template>

<script>
import {
  ref, computed, onBeforeMount,
  onUnmounted,
} from '@vue/composition-api'
import {
  BCard, BButton, BRow, BCol, BTabs, BTab, BAlert, BSpinner,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'

import store from '@/store'
import { useRouter } from '@/@core/utils/utils'
import IAmOverlay from '@/components/IAmOverlay.vue'

import {
  convertISODateTime,
} from '@core/utils/filter'

// import storeModule from '@train/trainStoreModule'
import useTrainHandle from '@train/useTrainHandle'
import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTabs,
    BTab,
    BAlert,
    BSpinner,

    IAmOverlay,

    CartTicket: () => import('./components/CartTicket.vue'),
    TrainFront: () => import('./components/TrainFront.vue'),
    TrainCoaches: () => import('./components/TrainCoaches.vue'),
    TrainCoachLayout: () => import('./components/TrainCoachLayout.vue'),
    AddonsAndPromotions: () => import('./components/AddonsAndPromotions.vue'),
    ResultHeaderTab: () => import('./components/ResultHeaderTab.vue'),
    NavBarSearch: () => import('../train-search/NavBarSearch.vue'),
  },
  setup() {
    const { router } = useRouter()
    const { toastWarning } = useToast()

    const {
      loading,
      searchTrain,
      getSearchPayloadArray,
      getResultTrainArray, // arr result
    } = useTrainHandle()

    const payloadSearch = JSON.parse(localStorage.getItem('trainSearch'))

    onBeforeMount(() => {
      if (!payloadSearch) {
        toastWarning({
          title: 'Vui lòng tìm kiếm lại hành trình !',
        })
        router.push({
          name: 'apps-trainSearch',
        })
      } else {
        searchTrain(payloadSearch)
      }
    })

    // if (!store.hasModule('app-train')) {
    //   store.registerModule('app-train', storeModule)
    // }
    // resetStore()

    onUnmounted(() => {
      store.commit('app-train/RESET_STORE')

      // if (store.hasModule('app-train')) {
      //   store.commit('app-train/REMOVE_BUTTON_DATA', { reset: true })
      //   store.unregisterModule('app-train')
      // }
    })

    // const buttonData = ref([
    //   { id: 1, name: 'Product 1' },
    //   { id: 2, name: 'Product 2' },
    //   { id: 3, name: 'Product 3' },
    //   { id: 4, name: 'Product 4' },
    //   { id: 5, name: 'Product 5' },
    //   { id: 6, name: 'Product 6' },
    //   { id: 23, name: 'Product 23' },
    //   { id: 42, name: 'Product 42' },
    //   { id: 53, name: 'Product 53' },
    //   { id: 64, name: 'Product 64' },
    //   { id: 75, name: 'Product 75' },
    //   { id: 16, name: 'Product 16' },
    // ])

    const tabIndex = computed({
      get() {
        return store.state['app-train'].tabIndex
      },
      set(newValue) {
        store.commit('app-train/SET_TAB_INDEX', newValue)
      },
    })

    return {
      isEmpty,
      loading,
      tabIndex,
      getSearchPayloadArray,
      convertISODateTime,
      payloadSearch,
      getResultTrainArray,
    }
  },
}
</script>

<style lang="scss">

</style>
