var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pb-3"
  }, [_c('NavBarSearch', {
    attrs: {
      "payload": _vm.payloadSearch
    }
  }), _c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    staticClass: "px-50",
    attrs: {
      "sm": "12",
      "lg": "8",
      "xl": "9"
    }
  }, [_c('div', {
    attrs: {
      "id": "result-component"
    }
  }, [_c('ResultHeaderTab'), _c('b-tabs', {
    attrs: {
      "nav-wrapper-class": "d-none"
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, _vm._l(_vm.getSearchPayloadArray, function (_, itineraryIndex) {
    return _c('b-tab', {
      key: itineraryIndex
    }, [_vm.loading ? [_c('div', {
      staticClass: "d-flex-center text-primary mt-2"
    }, [_c('b-spinner', {
      staticClass: "mr-50",
      attrs: {
        "variant": "primary"
      }
    }), _c('span', [_vm._v("Đang tải ...")])], 1)] : _vm.isEmpty(_vm.getResultTrainArray[itineraryIndex]) && !_vm.loading ? [_c('b-alert', {
      attrs: {
        "show": "",
        "variant": "warning"
      }
    }, [_c('h4', {
      staticClass: "text-center alert-heading"
    }, [_vm._v(" Không tìm thấy chuyến tàu trên hành trình này ! ")])])] : !_vm.isEmpty(_vm.getResultTrainArray[itineraryIndex]) ? [_c('div', [_c('TrainFront', {
      attrs: {
        "itineraryIndex": itineraryIndex,
        "trains": _vm.getResultTrainArray[itineraryIndex]
      }
    })], 1), _c('div', [_c('TrainCoaches', {
      attrs: {
        "itineraryIndex": itineraryIndex
      }
    })], 1), _c('div', [_c('TrainCoachLayout', {
      attrs: {
        "itineraryIndex": itineraryIndex
      }
    })], 1), _c('div')] : _vm._e()], 2);
  }), 1)], 1)]), ['lg', 'xl'].includes(_vm.appBreakPoint) ? _c('b-col', {
    staticClass: "px-50",
    attrs: {
      "lg": "4",
      "xl": "3"
    }
  }, [_c('CartTicket')], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }